import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { ViewComponent } from './view/view.component';
import { ItemComponent } from './list/item/item.component';
import { ProductRouting } from './products-routing.module';
import { ProductsService } from './products.service';
import { NouisliderModule } from 'ng2-nouislider';
import { FormsModule } from '@angular/forms';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';


@NgModule({
    declarations: [ListComponent, ViewComponent, ItemComponent],
    imports: [
        CommonModule,
        ProductRouting,
        NouisliderModule,
        FormsModule,
        ConfirmationPopoverModule
    ],
    providers: [
        ProductsService
    ]
})
export class ProductsModule {
}
