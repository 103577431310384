import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpSentEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { httpOptions } from '../services/BaseService';
import { Address } from './Address';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ProfileService {
    protected host = environment.host;
    private url = this.host + '/v1/profile';

    constructor(private http: HttpClient) {}

    uploadAvatar(formData: FormData): Observable<HttpEvent<any>> {
        const url = `${this.url}/avatar`;

        return this.http.post<HttpSentEvent>(url, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    getAddresses(): Observable<Address[]> {
        const url = `${this.url}/address`;
        return this.http.get<Address[]>(url, httpOptions);
    }
}
