import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from '../products/product';
import { BaseService, httpOptions } from '../services/BaseService';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CartService {
    protected host = environment.host;
    cart: Product[] = [];

    private url = this.host + '/v1/product/1';

    constructor(private http: HttpClient) {
        this.loadFromLocalStorage();
    }

    addToCart(product: Product) {
        const existProduct = this.cart.find(value => {
            return value.id === product.id;
        });

        if (existProduct) {
            existProduct.orderedAmount += product.orderedAmount;
        } else {
            this.cart.push(product);
        }

        this.saveToLocalStorage();
    }

    findProduct(product: Product) {
    }

    getCart(): Product[] {
        return this.cart;
    }

    clearCart() {
        this.cart = [];
        this.saveToLocalStorage();
        return this.cart;
    }

    removeItem(product) {
        const idx = this.cart.findIndex(i => i === product);
        if (idx !== -1) {
            const removed = this.cart.splice(idx, 1);
        }
        this.saveToLocalStorage();
    }

    totalSum() {
        let sum = 0;
        this.cart.map((product) => {
            sum += product.price * product.orderedAmount;
        });
        return sum;
    }

    saveToLocalStorage() {
        localStorage.setItem('cart', JSON.stringify(this.cart));
    }

    loadFromLocalStorage() {
        let cart = JSON.parse(localStorage.getItem('cart'));
        if (cart === null) {
            cart = [];
        }

        cart = cart.map(data => new Product(data));
        this.cart = cart;
    }

    getCartSize() {
        return this.cart.length;
    }

    getList(): Observable<Product[]> {
        const url = `${this.url}`;

        return this.http.get(url)
            .pipe(
                map((data: Product[]) => data.map(product => new Product(product)))
            );
    }

    getData(page): Observable<HttpResponse<Product[]>> {
        const url = `${this.url}?page=${page}`;
        return this.http.get<HttpResponse<Product[]>>(url, httpOptions);
    }

}
