import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-view-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit {
  @Input() accountData;
  address: Address = {name: '', address: '', city: '', postIndex: ''};
  addresses: Address[] = [
    {name: 'Дом', address: 'Скляренко 26 оф.16006', city: 'Самара', postIndex: '443086'},
    {name: 'Работа', address: 'Самарская 11 кв.11111', city: 'Самара', postIndex: '443086'},
  ];

  constructor() { }

  ngOnInit(): void {
  }

  addAddress() {
    this.addresses.push(this.address);
    this.address = {name: '', address: '', city: '', postIndex: ''};
  }
}

interface Address {
  name: string;
  city: string;
  postIndex: string;
  address: string;
}
