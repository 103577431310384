import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { Cart } from '../Cart';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  constructor(
      private cartService: CartService
  ) { }

  ngOnInit() {
  }

  getCart() {
    return this.cartService.getCart();
  }

  totalSum() {
    return this.cartService.totalSum();
  }

  clearCart() {
    this.cartService.clearCart();
  }

  removeItem(product) {
    this.cartService.removeItem(product);
  }

}
