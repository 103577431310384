<div class="clearfix">
    <a class="shop-tooltip float-right text-lightest" href="javascript:void(0)" title="Add to wishlist">
        <i class="ion ion-ios-heart"></i>
    </a>
    <a class="shop-tooltip float-right text-lightest mr-3" href="javascript:void(0)" title="Share">
        <i class="ion ion-md-share"></i>
    </a>
</div>
<a class="d-block" href="#">
    <img alt class="mb-2" src="{{product.getImageUrl()}}" style="max-width: 75%; max-height: 200px">
</a>
<h6 class="font-weight-normal">
    <a class="text-body" href="#">{{product.title}}</a>
</h6>
<!-- Rate -->
<div *ngIf="false" class="mb-3">
    <div class="ui-stars">
        <div class="ui-star filled">
            <i class="ion ion-md-star"></i>
            <i class="ion ion-md-star"></i>
        </div>
        <div class="ui-star filled">
            <i class="ion ion-md-star"></i>
            <i class="ion ion-md-star"></i>
        </div>
        <div class="ui-star filled">
            <i class="ion ion-md-star"></i>
            <i class="ion ion-md-star"></i>
        </div>
        <div class="ui-star filled">
            <i class="ion ion-md-star"></i>
            <i class="ion ion-md-star"></i>
        </div>
        <div class="ui-star half-filled">
            <i class="ion ion-md-star"></i>
            <i class="ion ion-md-star"></i>
        </div>
    </div>
    &nbsp;
    <a class="text-muted small" href="#">55</a>
</div>

<strong class="d-block mb-3">{{product.price | currency}}</strong>

<div class="input-group d-inline-flex w-auto justify-content-center align-items-center">
    <span class="input-group-prepend">
        <button
                (click)="decrease()"
                class="btn btn-default icon-btn md-btn-flat"
                type="button"
                [disabled]="product.orderedAmount <= 0"

                mwlConfirmationPopover
                popoverMessage="Действительно удалить товар из корзины?"
                confirmText="Да"
                cancelText="Нет"
                placement="top"
                [isDisabled]="product.orderedAmount !== 1"
                (confirm)="deleteFromCart()"
                >-</button>
    </span>
    <input class="form-control text-center" style="width: 46px;" type="text" value="{{product.orderedAmount}}">
    <span class="input-group-append">
        <button (click)="increase()" class="btn btn-default icon-btn md-btn-flat" type="button">+</button>
    </span>
</div>
<br>
<button (click)="addToCart()" [disabled]="product.orderedAmount === 0 || inCart()"
        class="btn btn-outline-primary">
    {{total() | currency }} {{inCart() ? '' : '+' }}<span class="ion" [ngClass]="inCart() ? 'ion-ios-checkmark-circle' : 'ion-md-cart'"></span>
</button>
