<div id="shop-checkout-wizard-3" class="animated fadeIn">
    <!-- Shopping cart table -->
    <h6 class="text-light small font-weight-normal">
        <span class="ion ion-md-cart"></span> &nbsp; Заказ
    </h6>
    <div class="table-responsive">
        <table class="table table-bordered m-0" style="min-width:550px;">
            <tbody>

            <tr *ngFor="let product of getCart()">
                <td class="p-4">
                    <div class="media align-items-center">
                        <img src="{{product.image}}" class="d-block ui-w-40 ui-bordered mr-4" alt>
                        <div class="media-body">
                            <a href="#" class="d-block text-body">{{product.title}}</a>
                            <small><span class="text-muted">{{product.description}}</span></small>
                        </div>
                    </div>
                </td>
                <!-- Set column width -->
                <td class="align-middle p-4" style="width: 66px;">x{{product.orderedAmount}}</td>
                <!-- Set column width -->
                <td class="text-right font-weight-semibold align-middle p-4" style="width: 100px;">{{ product.getTotal() | currency }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <!-- / Shopping cart table -->

    <h6 class="text-light small font-weight-normal mt-5">
        <span class="ion ion-md-card"></span> &nbsp; Способ оплаты
    </h6>
    <div class="d-flex justify-content-start align-items-center rounded ui-bordered p-3" *ngIf="checkoutForm.get('payment').get('paymentType').value == 'card'">
        <img src="assets/img/payment/Visa-light.png" class="ui-payment-small mr-1" alt> XXXX-XXXX-XXXX-{{ getSecureCard() | mask: '0000-99'}}
    </div>
    <div class="d-flex justify-content-start align-items-center rounded ui-bordered p-3" *ngIf="checkoutForm.get('payment').get('paymentType').value == 'paypal'">
        <img src="assets/img/payment/Visa-light.png" class="ui-payment-small mr-1" alt> PayPal
    </div>

    <h6 class="text-light small font-weight-normal mt-5">
        <span class="ion ion-md-home"></span> &nbsp; Информация о доставке
    </h6>
    <div class="ui-bordered p-3">
        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="text-muted small">Имя</div>
                {{getShippingForm().get('customerName')?.value}}
            </div>
            <div class="col-md-3 mb-3">
                <div class="text-muted small">Телефон</div>
                +7 {{getShippingForm().get('phone')?.value | mask: '(000) 0000 00-00'}}
            </div>
            <div class="col-md-3 mb-3">
                <div class="text-muted small">Email</div>
                {{getShippingForm().get('email').value}}
            </div>
            <div class="col-md-3 mb-3">
                <div class="text-muted small">Регион</div>
                {{ getRegion(getShippingForm().get('regionId')?.value)?.label }}
            </div>
            <div class="col-md-3 mb-3">
                <div class="text-muted small">Город</div>
                {{getShippingForm().get('city')?.value}}
            </div>
            <div class="col-md-3 mb-3">
                <div class="text-muted small">Индекс</div>
                {{getShippingForm().get('postIndex')?.value}}
            </div>
            <div class="col-12">
                <div class="text-muted small">Адрес</div>
                {{getShippingForm().get('address').value}}
            </div>
        </div>
    </div>

    <h6 class="text-light small font-weight-normal mt-5">
        <span class="ion ion-md-globe"></span> &nbsp; Способ доставки
    </h6>
    <div class="rounded ui-bordered p-3">
        <span>{{getDelivery()?.title}} - <strong>{{getDelivery()?.price == 0 ? 'бесплатно' : getDelivery()?.price | currency}}</strong> </span>
        <br>
        <small class="text-muted">{{getDelivery()?.description}}</small>
    </div>

    <hr class="mt-5 mb-4">

    <div class="text-right">
        <div class="text-muted">Итого</div>
        <div class="text-large">
            <strong>{{ totalSum() | currency }}</strong>
        </div>
    </div>
</div>
