import { BrowserModule, Title } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// *******************************************************************************
// NgBootstrap

import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// App

import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { CartModule } from './cart/cart.module';
import { ProductsModule } from './products/products.module';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// *******************************************************************************
//

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { CheckoutModule } from './checkout/checkout.module';
import { TextMaskModule } from 'angular2-text-mask';
import { CategoryModule } from './category/category.module';
import { ProfileModule } from './profile/profile.module';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './services/auth.interceptor';
import { CustomDateParserFormatter } from './helpers/CustomDateParserFormatter';
import { CustomDateAdapter } from './helpers/CustomDateAdapter';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AppInjector } from './services/app-injector.service';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    // Pages
    HomeComponent,
  ],

  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastClass: 'toast'
    }) ,

    // App
    AuthModule,
    ProductsModule,
    CategoryModule,
    ProfileModule,
    CartModule,
    CheckoutModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    TextMaskModule,
    ConfirmationPopoverModule.forRoot({
      cancelButtonType: 'default btn-sm',
      confirmButtonType: 'primary btn-sm'
    }),

  ],

  providers: [
    Title,
    AppService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'RUB'},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
