<div class="bg-lighter small py-2">
  <div class="container d-flex justify-content-between px-3">
    <div class="text-muted">
      <strong>24/7 Служба поддержки: </strong>(800) 123-4567</div>
    <div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <a class="dropdown-toggle text-body font-weight-semibold" href="javascript:void(0)" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>Самара</a>
        <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style="position: absolute; will-change: top, left; top: 15px; left: 27px;" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)">Самара</a>
          <a class="dropdown-item" href="javascript:void(0)">Москва</a>
          <a class="dropdown-item" href="javascript:void(0)">Санкт-Питербург</a>
        </div>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg px-0" [ngClass]="currentBg()">
  <div class="container flex-lg-wrap px-3">
    <a routerLink="/product" class="navbar-brand text-large font-weight-bolder line-height-1 py-3">Продукты24</a>

    <div class="navbar-text py-lg-3 pl-3 mr-auto">
      <a href="javascript:void(0)"><i class="ion ion-logo-vk text-white align-middle"></i></a>
      <a class="ml-3" href="javascript:void(0)"><i class="ion ion-logo-twitter text-white align-middle"></i></a>
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".shop-header" (click)="isExpanded = !isExpanded">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
      <div class="navbar-nav mr-auto">
        <a class="nav-item nav-link" routerLink="/product">Каталог</a>
        <a class="nav-item nav-link" routerLink="/product">Акции</a>
        <a class="nav-item nav-link" routerLink="/product">Товары недели</a>
      </div>
    </div>

    <!-- Menu -->
    <app-navbar-menu *ngIf="false" class="shop-header navbar-collapse collapse col-lg-12 flex-wrap order-lg-2 px-0"></app-navbar-menu>
    <!-- / Menu -->

    <div class="shop-header navbar-collapse collapse order-lg-1" [ngbCollapse]="!isExpanded">
      <div class="navbar-nav align-items-lg-center order-lg-2 py-lg-1 ml-auto">
        <!-- Search -->
        <li class="nav-item">
          <label class="nav-link navbar-search-box active" [ngClass]="{ 'active': searchExpanded }">
            <i class="ion ion-ios-search navbar-icon align-middle"></i>
            <span class="navbar-search-input">
                <input type="text" class="form-control mx-2" placeholder="Поиск..." style="width:140px" (focus)="searchExpanded=true">
                <a href="javascript:void(0)" class="navbar-search-cancel" (click)="searchExpanded=false">×</a>
              </span>
          </label>
        </li>

        <!-- Cart -->
        <li class="shop-cart-dropdown nav-item" ngbDropdown>
          <a class="nav-link hide-arrow text-nowrap ml-lg-2" href="javascript:void(0)" ngbDropdownToggle>
            <i class="ion ion-md-basket navbar-icon align-middle"></i>
            <span class="badge badge-danger indicator">{{cartSize()}}</span>
            <span class="d-lg-none align-middle">&nbsp; Cart</span>
          </a>
          <ul class="dropdown-menu-right" ngbDropdownMenu>
            <app-cart-view-popup></app-cart-view-popup>
          </ul>
        </li>

        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

        <a class="nav-item nav-link" routerLink="/auth/sign-in" *ngIf="!isLogged()">Вход</a>
        <a class="nav-item nav-link" routerLink="/auth/sign-up" *ngIf="!isLogged()">Регистрация</a>
        <li class="nav-item" ngbDropdown *ngIf="isLogged()">
          <a class="nav-link hide-arrow ml-lg-2" href="javascript:void(0)" ngbDropdownToggle>
            <img [src]="member?.getAvatar()" alt="" class="ui-w-30 rounded-circle align-middle">
            <span class="align-middle">&nbsp; {{member?.name}}</span>
          </a>
          <div class="dropdown-menu-right" ngbDropdownMenu>
            <a class="dropdown-item" routerLink="/profile">Профиль</a>
            <a class="dropdown-item" routerLink="/profile/orders">История заказов</a>
            <a class="dropdown-item" routerLink="/profile/favorites">Избранное</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">Выход</a>
          </div>
        </li>

      </div>
    </div>
  </div>
</nav>
