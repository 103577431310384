import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppInjector } from './app-injector.service';
import { Injectable } from '@angular/core';

export const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
//    observe: 'response' as 'body',
};

@Injectable({providedIn: 'root'})
export abstract class BaseService {
    public host = environment.host;
}
