import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CheckoutService } from '../../checkout.service';
import { Region } from '../../region';
import { DeliveryMethod } from '../../DeliveryMethod';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../auth/auth.service';
import { Member } from '../../../profile/Member';
import { ProfileService } from '../../../profile/profile.service';
import { Address } from '../../../profile/Address';
import { Category } from '../../../category/category';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-checkout-step-shipping',
    templateUrl: './shipping.component.html',
    styleUrls: [
        './shipping.component.scss',
        '../../../../vendor/libs/ng-select/ng-select.scss'
    ]
})
export class ShippingComponent implements OnInit {
    @ViewChild(NgSelectComponent)
    ngSelect: NgSelectComponent;
    @Input() shippingForm: FormGroup;
    member: Member;
    addresses: Address[] = [];
    selectedAddress: Address;

    constructor(
        private checkoutService: CheckoutService,
        private authService: AuthService,
        private profileService: ProfileService
    ) {
        if (authService.isLoggedIn()) {
            authService.currentMember$.subscribe(
                member => {
                    this.member = member;
                    this.shippingForm.get('customerName').setValue(member.name);
                    this.shippingForm.get('phone').setValue(member.phone);
                    this.shippingForm.get('email').setValue(member.email);
                }
            );
            profileService.getAddresses().subscribe(
                body => {
                    console.log(body);
                    this.addresses = body.map(value => new Address(value));
                },
                error => {
                    console.log('Error in componenet');
                    console.log(error);
                }
            );
        }
    }

    ngOnInit(): void {
    }

    selectAddress(address: Address) {
        if (address) {
            this.shippingForm.get('city').setValue(address.city);
            this.shippingForm.get('address').setValue(address.address);
            this.shippingForm.get('postCode').setValue(address.postCode);
        } else {
            this.shippingForm.get('city').setValue('');
            this.shippingForm.get('address').setValue('');
            this.shippingForm.get('postCode').setValue('');
        }
    }

    selectDeliveryMethod(method: number) {
        this.shippingForm.get('deliveryMethod').setValue(method);
    }

    getRegions(): Region[] {
        return this.checkoutService.getRegions();
    }

    getDeliveryMethods(): DeliveryMethod[] {
        return this.checkoutService.getDeliveryMethods();
    }
}
