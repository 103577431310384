<!-- Path -->
<div class="bg-light">
    <div class="container text-big py-4 px-3">
        Избранное
    </div>
</div>
<!-- / Path -->

<div class="container px-3 my-5 clearfix">
    Пусто
</div>
