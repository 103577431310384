import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = new Date(value);
            return {
                day : date.getDay(),
                month : date.getMonth(),
                year : date.getFullYear()
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? formatDate(`${date.year}-${date.month}-${date.day}`, 'shortDate', 'ru-RU') : '';
    }
}
