import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../../../cart/cart.service';
import { CheckoutService } from '../../checkout.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkout-step-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() checkoutForm: FormGroup;

  constructor(
      private cartService: CartService,
      private checkoutService: CheckoutService
  ) { }

  ngOnInit(): void {
  }

  getCart() {
    return this.checkoutForm.get('products').value;
  }

  getSecureCard() {
    const numberCard = this.checkoutForm.get('payment').get('cardNumber').value;
    return numberCard.slice(12, numberCard.length);
  }

  totalSum() {
    return this.cartService.totalSum() + this.getDelivery().price;
  }

  getShippingForm() {
    return this.checkoutForm.get('shipping');
  }

  getRegion(regionId: number) {
    return this.checkoutService.findRegionById(regionId);
  }

  getDelivery() {
    return this.checkoutService.findDeliveryById(this.getShippingForm().get('deliveryMethod').value);
  }

}
