import { Component, OnInit } from '@angular/core';
import { Category } from '../category';
import { CategoryService } from '../category.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
    categories: Category[] = [];
    load = false;
    parentSlug = '';
    breadsCrumbs: Category[] = [];
    child: string[];

    constructor(
        private categoryService: CategoryService,
        private route: ActivatedRoute
    ) {
        route.url.subscribe((data) => {
            this.child = data.map(value => value.path);
            const arraySize = data.length;
            if (arraySize > 0) {
                this.parentSlug = data[arraySize - 1].path;
            }
        });
        this.loadData(1);
    }

    getData(): Category[] {
        this.breadsCrumbs = [];
        let data = this.categories;
        this.child.forEach(slug => {
            const findCategory = data.find(category => {
                return category.slug === slug;
            });
            if (findCategory) {
                this.breadsCrumbs.push(findCategory);
                data = findCategory.children;
            } else {
                data = [];
            }
        });

        return data;
    }

    ngOnInit(): void {
    }

    loadData(page) {
        this.categoryService.getList(page)
            .subscribe(data => {
                this.categories = data.body.map(value => new Category(value));
                this.getData();
                this.load = true;
            });
    }

    breadCrumbs(category: Category) {
        if (!category) { return []; }
        let result = [];
        const parentCategory = this.getCategoryById(category.parentId);
        if (parentCategory) {
            result = this.breadCrumbs(parentCategory);
        }

        result.push(category);

        return result;
    }

    getCategoryBySlug(slug: string) {
        return this.categories.find((value) => {
            return value.slug === slug;
        });
    }

    getCategoryById(id: number) {
        return this.categories.find((value) => {
            return value.id === id;
        });
    }

    getCurrentCategory() {
        return this.categories.find(value => value.slug === this.parentSlug);
    }
}
