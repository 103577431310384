import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-view-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  @Input() accountData;

  constructor() { }

  ngOnInit(): void {
  }

}
