import { consoleTestResultHandler } from 'tslint/lib/test';
import { environment } from '../../environments/environment';

export class Category {
    id: number;
    parentId: number;
    parent: Category;
    name: string;
    description: string;
    slug: string;
    image: string;
    children: Category[] = [];

    constructor(json: any, parent?: Category) {
        this.id = json.id;
        this.parentId = json.parent_id;
        this.name = json.name;
        this.description = json.description;
        this.slug = json.slug;
        this.image = json.image;
        this.parent = json.parent;
        if (json.children) {
            this.children = json.children.map(data => new Category(data, this));
        }
    }

    getUrl(): string {
        let fullUrl = '';
        if (this.parent) {
            fullUrl += this.parent.getUrl();
        }
        return fullUrl + this.slug;
    }

    public getImageUrl() {
        return environment.storage_host + '/images/categories/' + this.image;
    }
}
