import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view/view.component';
import { CartRouting } from './cart-routing.module';
import { CartService } from './cart.service';
import { PopupComponent } from './popup/popup.component';

@NgModule({
    declarations: [ViewComponent, PopupComponent],
    imports: [
        CommonModule,
        CartRouting,
    ],
    exports: [
        PopupComponent
    ],
    providers: [
        CartService,
    ]
})
export class CartModule { }
