<div class="clearfix">
    <a href="javascript:void(0)" class="shop-tooltip float-right text-lightest" title="Add to wishlist">
        <i class="ion ion-ios-heart"></i>
    </a>
    <a href="javascript:void(0)" class="shop-tooltip float-right text-lightest mr-3" title="Share">
        <i class="ion ion-md-share"></i>
    </a>
</div>
<a routerLink="{{category.slug}}" class="d-block">
    <img src="{{category.getImageUrl()}}" class="mb-2" alt style="max-width: 75%; max-height: 200px">
</a>
<h6 class="font-weight-normal">
    <a routerLink="{{category.slug}}" class="text-body">{{category.name}}</a>
</h6>

<div class="text-muted">
    {{category.description}}
</div>
