import { Component, Input, OnInit } from '@angular/core';
import { Member } from '../../Member';
import { ModalDismissReasons, NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { ProfileService } from '../../profile.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';

@Component({
    selector: 'app-profile-view-general',
    templateUrl: './general.component.html',
    styleUrls: [
        './general.component.scss',
        '../../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss',
        '../../../../vendor/libs/ngb-timepicker/ngb-timepicker.scss',
        '../../../../vendor/libs/ngx-color-picker/ngx-color-picker.scss'
    ]
})
export class GeneralComponent implements OnInit {
    @Input() member: Member;
    croppedImage: string = '';
    imageChangedEvent: any = '';

    maxDate: NgbDate;
    uploadProgress = 0;
    uploadProgressStatus = 'secondary';

    constructor(
        private calendar: NgbCalendar,
        private modalService: NgbModal,
        private profileService: ProfileService,
        private authService: AuthService
    ) {
        this.maxDate = calendar.getPrev(calendar.getToday(), 'y', 10);
    }

    ngOnInit(): void {
        console.log();
    }

    open(content, options = {}) {
        this.modalService.open(content, options).result.then((result) => {
            console.log(`Closed with: ${result}`);
        }, (reason) => {
            console.log(`Dismissed ${this.getDismissReason(reason)}`);
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    fileChangeListener(event) {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    uploadAttachmentToServer(modal) {
        const fileToUpload: File = new File([base64ToFile(this.croppedImage)], 'avatar.png', {type: 'image/png'});
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload, fileToUpload.name);
        this.uploadProgressStatus = 'secondary';
        this.uploadProgress = 0;

        this.profileService.uploadAvatar(formData)
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            this.uploadProgress = Math.round(event.loaded * 100 / event.total);
                            break;
                        case HttpEventType.Response:
                            return event;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    this.uploadProgressStatus = 'danger';
                    return of(`${fileToUpload.name} upload failed.`);
                })
            )
            .subscribe((event: any) => {
                if (typeof (event) === 'object') {
                    this.uploadProgressStatus = 'success';
                    this.authService.currentMemberReplay.next(new Member(event.body));
                    this.member = new Member(event.body);
                    this.modalService.dismissAll();
                    console.log(event.body);
                }
            });
    }
}
