export class Address {
    id: number;
    name: string;
    postCode: string;
    city: string;
    address: string;
    comment: string;

    constructor(json: any = null) {
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            this.postCode = json.postal_code;
            this.city = json.city;
            this.address = json.address;
            this.comment = json.comment;
        }
    }
}
