import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkout-step-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() paymentForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  setPaymentType(event: NgbTabChangeEvent) {
    this.paymentForm.get('paymentType').setValue(event.nextId);
  }
}
