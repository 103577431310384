<div class="layout-wrapper layout-1" [ngClass]="{'layout-loading': !initialized}">
  <div class="layout-inner">
    <app-layout-navbar></app-layout-navbar>
    <div class="layout-container">
      <div class="layout-content">
        <div class="container-fluid router-transitions d-flex align-items-stretch flex-grow-1 p-0">
          <router-outlet></router-outlet>
        </div>

        <app-layout-footer></app-layout-footer>
      </div>
    </div>
  </div>
</div>
<div class="layout-overlay" (click)="closeSidenav()"></div>
