<ng-container  *ngFor="let product of getCart()">
    <div class="media align-items-center p-3">
        <img src="{{product.getImageUrl()}}" class="d-block ui-w-40" alt="">
        <div class="media-body small mx-3">
            <a href="#" class="text-body font-weight-semibold">{{product.title}}</a>
            <div class="text-muted">{{product.price}} × {{product.orderedAmount}}</div>
        </div>
        <a href="javascript:void(0)" class="text-danger text-xlarge font-weight-light" (click)="removeItem(product)">
            ×
        </a>
    </div>
    <hr class="m-0" style="border-color: rgba(78,81,85,0.075)">
</ng-container>

<div class="d-flex justify-content-between align-items-center p-3">
    <div>
        <span class="text-muted">Всего:</span>&nbsp;
        <span class="font-weight-bold">{{totalSum() | currency}}</span>
    </div>
    <div>
        <button type="button" class="btn btn-default btn-sm md-btn-flat text-expanded" [routerLink]="['/cart']">Корзина</button>
        <button type="button" class="btn btn-primary btn-sm md-btn-flat text-expanded ml-1" [routerLink]="['/checkout']">Оформить</button>
    </div>
</div>
