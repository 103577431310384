<!-- Path -->
<div class="bg-light">
    <div class="container text-big py-4 px-3">
        Оформление заказа
    </div>
</div>
<!-- / Path -->

<div class="container px-3 my-5 clearfix" *ngIf="!allowCheckout()">
    <div class="card">
        <div class="card-body">
            Ваша корзина еще пуста:( <a routerLink="/product">Давайте наполним ее!</a>
        </div>
    </div>
</div>

<div id="shop-checkout-wizard" class="bg-white container ui-bordered p-0 pb-4 my-5" *ngIf="allowCheckout()">
    <aw-wizard
            class="pt-4"
            [navBarLocation]="'top'"
            [navBarLayout]="'large-empty-symbols'"
            #wizard
            [formGroup]="checkoutForm"
    >
        <div class="border-right-0 border-left-0 ui-bordered p-4 p-lg-5 mb-4 sw-container tab-content">
            <aw-wizard-step stepTitle="Доставка" [canExit]="checkoutForm.get('shipping').status == 'VALID'">
                <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                    <span class="sw-done-icon ion ion-md-checkmark" *ngIf="wizardStep.completed && !wizardStep.editing"></span>
                    <span class="sw-icon ion ion-ios-airplane" *ngIf="!wizardStep.completed || wizardStep.editing"></span>
                </ng-template>
                <app-checkout-step-shipping [shippingForm]="checkoutForm.get('shipping')"></app-checkout-step-shipping>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="Оплата" [canExit]="checkoutForm.get('payment').status == 'VALID'">
                <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                    <span class="sw-done-icon ion ion-md-checkmark" *ngIf="wizardStep.completed"></span>
                    <span class="sw-icon ion ion-md-card" *ngIf="!wizardStep.completed"></span>
                </ng-template>
                <app-checkout-step-payment [paymentForm]="checkoutForm.get('payment')"></app-checkout-step-payment>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="Подтверждение">
                <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                    <span class="sw-done-icon ion ion-md-checkmark" *ngIf="wizardStep.completed"></span>
                    <span class="sw-icon ion ion-md-checkmark-circle-outline" *ngIf="!wizardStep.completed"></span>
                </ng-template>
                <app-checkout-step-confirmation [checkoutForm]="checkoutForm"></app-checkout-step-confirmation>
            </aw-wizard-step>
        </div>

        <div class="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end justify-content-center">
            <div class="mr-2 sw-btn-group" role="group">
                <button class="btn btn-secondary sw-btn-prev text-expanded text-uppercase mx-2 waves-effect" [class.disabled]="!wizard.hasPreviousStep()" type="button" awPreviousStep>← Назад</button>
                <button class="btn btn-secondary sw-btn-next text-expanded text-uppercase mx-2 btn-primary waves-effect" [class.disabled]="!wizard.hasNextStep()" [disabled]="!wizard.currentStep?.canExit" type="button" awNextStep>Дальше →</button>
                <button class="btn sw-btn-finish text-expanded text-uppercase mx-2 btn-primary" type="button" [disabled]="checkoutForm.status != 'VALID'" (click)="sendForm()">Отправить</button>
            </div>
        </div>
    </aw-wizard>
</div>
