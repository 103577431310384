import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { ListComponent } from './category/list/list.component';
import { LayoutShopComponent } from './layout/layout-shop/layout-shop.component';

// *******************************************************************************
// Routes

const routes: Routes = [

  { path: '', redirectTo: '/category', pathMatch: 'full'},

  // { path: '', component: LayoutShopComponent, pathMatch: 'full', children: [
  //     { path: '', component: ListComponent },
  // ]},


  { path: 'home', component: Layout1Component, pathMatch: 'full', children: [
    { path: '', component: HomeComponent },
  ]},

  // 404 Not Found page
  { path: '**', component: NotFoundComponent }

];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
