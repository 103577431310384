import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from './product';
import { BaseService, httpOptions } from '../services/BaseService';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ProductsService {
    protected host = environment.host;
    private url = this.host + '/v1/product/1';

    constructor(private http: HttpClient) {}

    getList(): Observable<Product[]> {
        const url = `${this.url}`;

        return this.http.get(url)
            .pipe(
                map((data: Product[]) => data.map(product => new Product(product)))
            );
    }

    getData(page): Observable<HttpResponse<Product[]>> {
        const url = `${this.url}?page=${page}`;
        return this.http.get<HttpResponse<Product[]>>(url, httpOptions);
    }
}
