<div class="card-body pb-2">
    <button class="btn btn-primary">Добавить карту</button>
    <div class="ui-bordered p-4">
        <div class="form-group">
            <label class="d-flex justify-content-between align-items-end">
                <span class="form-label mb-0">Номер карты</span>
                <img src="assets/img/payment/Visa-light.png" class="ui-payment-small" alt>
            </label>
            <input type="text" class="form-control"
                   placeholder="XXXX XXXX XXXX XXXX"
                   mask="0000 0000 0000 0000 99"
                   [validation]="true">
        </div>

        <div class="form-group">
            <label class="form-label">Держатель карты</label>
            <input type="text" class="form-control" placeholder="Имя на карте">
        </div>

        <div class="form-row">
            <div class="col">
                <label class="form-label">Срок окончания</label>
                <input type="text" class="form-control" placeholder="ММ/ГГ" mask="00/00" [validation]="true">
            </div>
            <div class="col">
                <label class="form-label">CVC</label>
                <input type="text" class="form-control" placeholder="XXX"
                       [hiddenInput]="true" mask="XXX" [validation]="true">
            </div>
        </div>
    </div>
</div>
