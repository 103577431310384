import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CheckoutService } from '../checkout.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CartService } from '../../cart/cart.service';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: [
        './view.component.scss',
        '../../../vendor/libs/ng2-archwizard/ng2-archwizard.scss',
    ],
    // encapsulation: ViewEncapsulation.None
})
export class ViewComponent implements OnInit {
    checkoutForm = this.fb.group({
        shipping: this.fb.group({
            customerName: ['', [Validators.required, Validators.minLength(3)]],
            // regionId: [null, Validators.required],
            city: ['', [Validators.required, Validators.minLength(3)]],
            postCode: ['', [Validators.required]],
            address: ['', [Validators.required, Validators.minLength(10)]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            deliveryMethod: [1, [Validators.required]],
        }),
        payment: this.fb.group({
            paymentType: ['card', [Validators.required]],
            cardNumber: ['', [Validators.required]],
            cardHolder: ['', [Validators.required]],
            cardExpired: ['', Validators.required],
            cardCvc: ['', Validators.required],
        }),
        products: [this.cartService.getCart()]
    });

    constructor(
        private cartService: CartService,
        private checkoutService: CheckoutService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.setPaymentValidators();
    }

    allowCheckout(): boolean {
        return this.cartService.getCart().length > 0;
    }

    setPaymentValidators() {
        const form = this.checkoutForm.get('payment');
        const cardCvcControl = form.get('cardCvc');
        const cardNumberControl = form.get('cardNumber');
        const cardHolderControl = form.get('cardHolder');
        const cardExpiredControl = form.get('cardExpired');

        form.get('paymentType').valueChanges
            .subscribe(paymentType => {

                if (paymentType === 'card') {
                    cardCvcControl.setValidators([Validators.required]);
                    cardNumberControl.setValidators([Validators.required]);
                    cardHolderControl.setValidators([Validators.required]);
                    cardExpiredControl.setValidators([Validators.required]);
                }

                if (paymentType === 'paypal') {
                    cardCvcControl.setValidators(null);
                    cardNumberControl.setValidators(null);
                    cardHolderControl.setValidators(null);
                    cardExpiredControl.setValidators(null);
                }

                cardCvcControl.updateValueAndValidity();
                cardNumberControl.updateValueAndValidity();
                cardHolderControl.updateValueAndValidity();
                cardExpiredControl.updateValueAndValidity();
            });
    }

    sendForm() {
        alert('Заказ отправлен');
    }
}
