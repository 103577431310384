<div class="bg-light">
    <div class="container py-4 px-3">
        <ol class="breadcrumb text-big m-0">
            <li class="breadcrumb-item">
                <a href="#">Вход</a>
            </li>
        </ol>
    </div>
</div>

<div class="container px-3 my-4">
    <form [formGroup]="signInForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
            <label class="col-form-label col-sm-2 text-sm-right">Телефон</label>
            <div class="col-sm-10">
                <input formControlName="phone" class="form-control" type="text"  placeholder="+7(___) ___-____" prefix="+7" mask="(000) 000 00 00" [showMaskTyped]="true" [validation]="true">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-form-label col-sm-2 text-sm-right">Пароль</label>
            <div class="col-sm-10">
                <input formControlName="password" class="form-control" type="password" placeholder="Пароль">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-10 ml-sm-auto">
                <button type="submit" class="btn btn-primary" [disabled]="!signInForm.valid">Вход</button>
            </div>
        </div>
    </form>
</div>
