<div id="shop-checkout-wizard-2" class="animated fadeIn" [formGroup]="paymentForm">
    <div class="mx-auto" style="max-width: 400px;">

        <ngb-tabset (tabChange)="setPaymentType($event)" class="nav-tabs-top mb-4">
            <ngb-tab id="card">
                <ng-template ngbTabTitle>
                    <span class="fas fa-credit-card text-big mx-3"></span>
                </ng-template>
                <ng-template ngbTabContent>
                    <div class="ui-bordered p-4">
                    <div class="form-group">
                        <label class="d-flex justify-content-between align-items-end">
                            <span class="form-label mb-0">Номер карты</span>
                            <img src="assets/img/payment/Visa-light.png" class="ui-payment-small" alt>
                        </label>
                        <input type="text" class="form-control"
                               placeholder="XXXX XXXX XXXX XXXX"
                               mask="0000 0000 0000 0000 99"
                               formControlName="cardNumber"
                               [validation]="true">
                    </div>

                    <div class="form-group">
                        <label class="form-label">Держатель карты</label>
                        <input type="text" class="form-control" placeholder="Имя на карте" formControlName="cardHolder">
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <label class="form-label">Срок окончания</label>
                            <input type="text" class="form-control" formControlName="cardExpired" placeholder="ММ/ГГ" mask="00/00" [validation]="true">
                        </div>
                        <div class="col">
                            <label class="form-label">CVC</label>
                            <input type="text" class="form-control" placeholder="XXX"
                                   formControlName="cardCvc"
                                   [hiddenInput]="true" mask="XXX" [validation]="true">
                        </div>
                    </div>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="paypal">
                <ng-template ngbTabTitle>
                    <span class="fab fa-cc-paypal text-big mx-3"></span>
                </ng-template>
                <ng-template ngbTabContent class="tab-pane">
                    <div class="ui-bordered p-4">
                        <button type="button" class="btn btn-lg btn-primary btn-block">
                            Продолжить с
                            <strong>
                                <em>PayPal</em>
                            </strong>
                        </button>
                    </div>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</div>
