import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { ItemComponent } from './list/item/item.component';
import { CategoryService } from './category.service';
import { CategoryRouting } from './category-routing.module';



@NgModule({
  declarations: [ListComponent, ItemComponent],
  imports: [
    CommonModule,
    CategoryRouting
  ],
  providers: [
    CategoryService
  ]
})
export class CategoryModule { }
