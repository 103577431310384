import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const authToken = localStorage.getItem('auth-token');

        /*
        * The verbose way:
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
          headers: req.headers.set('Authorization', authToken)
        });
        */
        // Clone the request and set the new header in one step.
        const authReq = request.clone({
            setHeaders: {
                Authorization: `Bearer ${authToken}`
            }
        });

        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
}
