import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../product';
import { CartService } from '../../../cart/cart.service';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './item.component.html',
  styleUrls: [
      './item.component.scss'
  ]
})
export class ItemComponent implements OnInit {
  @Input() product: Product;

  constructor(
      private cartService: CartService
  ) {
  }

  ngOnInit() {
    this.cartService.cart.forEach((value: Product) => {
      if (this.product.id === value.id) {
        this.product = value;
      }
    });
  }

  inCart() {
    return this.cartService.cart.includes(this.product);
  }

  total() {
    return this.product.orderedAmount * this.product.price;
  }

  increase() {
    this.product.orderedAmount++;
  }

  decrease() {
    if (this.product.orderedAmount > 1) {
      this.product.orderedAmount--;
    }
  }

  deleteFromCart() {
    this.product.orderedAmount = 0;
    this.cartService.removeItem(this.product);
  }

  addToCart() {
    this.cartService.addToCart(this.product);
  }

}
