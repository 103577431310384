<!-- Path -->
<div class="bg-light">
    <div class="container text-big py-4 px-3">
        Профиль
    </div>
</div>
<!-- / Path -->

<div class="container px-3 my-5 clearfix">
    <div class="border-right-0 border-left-0 ui-bordered p-4 p-lg-5 mb-4 sw-container">
        <div class="row no-gutters row-bordered row-border-light">
            <div class="col-md-3 pt-0">
                <div class="list-group list-group-flush account-settings-links">
                    <button type="button" class="list-group-item list-group-item-action" [ngClass]="{active: curTab === 'general'}" (click)="curTab = 'general'">Основное</button>
                    <button type="button" class="list-group-item list-group-item-action" [ngClass]="{active: curTab === 'security'}" (click)="curTab = 'security'">Безопасность</button>
                    <button type="button" class="list-group-item list-group-item-action" [ngClass]="{active: curTab === 'address'}" (click)="curTab = 'address'">Адреса</button>
                    <button type="button" class="list-group-item list-group-item-action" [ngClass]="{active: curTab === 'payments'}" (click)="curTab = 'payments'">Платежная информация</button>
                    <button type="button" class="list-group-item list-group-item-action" [ngClass]="{active: curTab === 'notifications'}" (click)="curTab = 'notifications'">Уведомления</button>
                </div>
            </div>
            <div class="sk-wave sk-primary" *ngIf="curTab === 'general' && !member">
                <div class="sk-rect sk-rect1"></div>
                <div class="sk-rect sk-rect2"></div>
                <div class="sk-rect sk-rect3"></div>
                <div class="sk-rect sk-rect4"></div>
                <div class="sk-rect sk-rect5"></div>
            </div>

            <app-profile-view-general class="col-md-9" *ngIf="curTab === 'general' && member" [member]="member"></app-profile-view-general>
            <app-profile-view-security class="col-md-9" *ngIf="curTab === 'security'" [accountData]="accountData"></app-profile-view-security>
            <app-profile-view-address class="col-md-9" *ngIf="curTab === 'address'" [accountData]="accountData"></app-profile-view-address>
            <app-profile-view-payment class="col-md-9" *ngIf="curTab === 'payments'" [accountData]="accountData"></app-profile-view-payment>
            <app-profile-view-notification class="col-md-9" *ngIf="curTab === 'notifications'" [accountData]="accountData"></app-profile-view-notification>
        </div>
    </div>

    <div class="text-right mt-3">
        <button type="button" class="btn btn-primary">Save changes</button>&nbsp;
        <button type="button" class="btn btn-default">Cancel</button>
    </div>

</div>
