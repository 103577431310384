import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Member } from '../Member';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: [
    '../../../vendor/libs/ng-select/ng-select.scss',
    '../../../vendor/styles/pages/account.scss',
    './view.component.scss',
  ]
})
export class ViewComponent implements OnInit {
  member: Member;

  curTab = 'general';
  languages = [
    { value: 'English', label: 'English' },
    { value: 'German', label: 'German' },
    { value: 'French', label: 'French' }
  ];

  accountData = {
    avatar: '1-small.png',
    name: 'Вася Пупкин Maxwell',
    username: 'nmaxwell',
    email: 'nmaxwell@mail.com',
    company: 'Company Ltd.',
    verified: false,

    info: {
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
          ' Mauris nunc arcu, dignissim sit amet sollicitudin iaculis, vehicula id urna.' +
          ' Sed luctus urna nunc. Donec fermentum, magna sit amet rutrum pretium, turpis dolor' +
          ' molestie diam, ut lacinia diam risus eleifend sapien. Curabitur ac nibh nulla.' +
          ' Maecenas nec augue placerat, viverra tellus non, pulvinar risus.',
      birthday: 'May 3, 1995',
      address: {
        country: 'Canada',
        postIndex: '443086',
        city: 'Самара',
        address: 'Скляренко 26',
        name: 'Скляренко 26',
      },
      languages: ['English'],
      phone: '+7 (917) 111 11-11',
      telegram: '',
      music: ['Rock', 'Alternative', 'Electro', 'Drum & Bass', 'Dance'],
      movies: ['The Green Mile', 'Pulp Fiction', 'Back to the Future', 'WALL·E',
        'Django Unchained', 'The Truman Show', 'Home Alone', 'Seven Pounds'],

      twitter: 'https://twitter.com/user',
      facebook: 'https://www.facebook.com/user',
      google: '',
      linkedin: '',
      instagram: 'https://www.instagram.com/user'
    },

    notifications: {
      comments: true,
      forum: true,
      followings: false,
      news: true,
      products: false,
      blog: true
    }
  };

  constructor(
      private authService: AuthService
  ) {
    this.authService.currentMember$.subscribe(
        member => {
          this.member = member;
        }
    );
  }

  ngOnInit(): void {
  }
}
