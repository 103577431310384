import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { httpOptions } from '../services/BaseService';
import { Member } from '../profile/Member';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthService {
    public redirectUrl = '/profile';
    protected host = environment.host;
    protected url = this.host + '/v1/auth';

    public readonly currentMemberReplay = new ReplaySubject<Member>();
    // private readonly currentMemberBehavior = new BehaviorSubject<Member>(new Member());

    public readonly currentMember$ = this.currentMemberReplay.asObservable();
    // public readonly currentMemberB$ = this.currentMemberBehavior.asObservable();

    constructor(private http: HttpClient) {
        if (this.getAuthorizationToken()) {
            this.updateCurrentMemberData();
        }
    }

    // public get currentMemberValue(): Member {
    //     return this.currentMemberBehavior.value;
    // }

    isLoggedIn(): boolean {
        return !!this.getAuthorizationToken();
    }

    getAuthorizationToken() {
        return localStorage.getItem('auth-token');
    }

    setAuthorizationToken(token: string) {
        localStorage.setItem('auth-token', token);
    }

    signUp(data): Observable<HttpResponse<string>> {
        const url = `${this.url}/sign-up`;
        return this.http.post<HttpResponse<string>>(url, data, httpOptions);
    }

    signIn(data): Observable<HttpResponse<string>> {
        const url = `${this.url}/sign-in`;
        return this.http.post<HttpResponse<string>>(url, data, httpOptions);
    }

    logout() {
        localStorage.removeItem('auth-token');
        localStorage.clear();
    }

    loadUserData(): Observable<Member> {
        const url = this.host + '/v1/member/current';
        return this.http.get<Member>(url, httpOptions);
    }

    public updateCurrentMemberData(): void {
        this.loadUserData().subscribe(
            body => {
                this.currentMemberReplay.next(new Member(body));
                // this.currentMemberBehavior.next(new Member(httpResponse.body));
            },
            error => {
                console.log('Logout:');
                console.log(error);
               // this.logout();
            }
        );
    }

    // Example, Variant two.
    // async getUser() {
    //   console.log('GetUserMethod');
    //   if (this.currentUser == null) {
    //     if (!localStorage.getItem('currentUser')) {
    //       const t = await this.loadUserData().toPromise();
    //       localStorage.setItem('currentUser', JSON.stringify(t));
    //     }
    //     this.currentUser = new User(JSON.parse(localStorage.getItem('currentUser')));
    //   }
    //
    //   return this.currentUser;
    // }

    private getCookie(name) {
        const splitCookie = document.cookie.split(';');
        for (const cookie of splitCookie) {
            const splitValue = cookie.split('=');
            if (splitValue[0] === name) {
                return splitValue[1];
            }
        }

        return '';
    }
}
