import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signInForm = this.fb.group({
    phone: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(
      private fb: FormBuilder,
      private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  submitForm() {
    this.authService.signIn(this.signInForm.getRawValue()).subscribe(
        data => {
          console.log(data);
        }
    );
  }

}
