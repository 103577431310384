<!-- Path -->
<div class="bg-light">
    <div class="container text-big py-4 px-3">
        Корзина
    </div>
</div>
<!-- / Path -->

<div class="container px-3 my-5 clearfix">
        <!-- Shopping cart table -->
        <div class="table-responsive">
            <table class="table table-bordered m-0">
                <thead>
                <tr>
                    <!-- Set columns width -->
                    <th class="text-center py-3 px-4" style="min-width: 400px;">Название</th>
                    <th class="text-right py-3 px-4" style="width: 100px;">Цена</th>
                    <th class="text-center py-3 px-4" style="width: 120px;">Кол-во</th>
                    <th class="text-right py-3 px-4" style="width: 100px;">Сумма</th>
                    <th class="text-center align-middle py-3 px-0" style="width: 40px;"><a href="javascript:void(0)" (click)="clearCart()" class="shop-tooltip float-none text-light"title="Clear cart"><i class="ino ion-md-trash"></i></a></th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let product of getCart()">
                    <td class="p-4">
                        <div class="media align-items-center">
                            <img src="{{product.getImageUrl()}}" class="d-block ui-w-60 ui-bordered mr-4" alt>
                            <div class="media-body">
                                <a href="#" class="d-block text-body">{{product.title}}</a>
                                <small>
                                    <span class="text-muted">{{product.description}}</span>
                                </small>
                            </div>
                        </div>
                    </td>
                    <td class="text-right font-weight-semibold align-middle p-4">{{product.price | currency }}</td>
                    <td class="align-middle p-4"><input type="text" class="form-control text-center" value="{{product.orderedAmount}}"></td>
                    <td class="text-right font-weight-semibold align-middle p-4">{{product.price * product.orderedAmount | currency }}</td>
                    <td class="text-center align-middle px-0"><a href="javascript:void(0)" (click)="removeItem(product)" class="shop-tooltip close float-none text-danger" title="Убрать">×</a></td>
                </tr>
                </tbody>
            </table>
        </div>
        <!-- / Shopping cart table -->

        <div class="d-flex flex-wrap justify-content-between align-items-center pb-4">
            <div class="mt-4">
                <label class="text-muted font-weight-normal">Промокод</label>
                <input type="text" placeholder="ABCD" class="form-control">
            </div>
            <div class="d-flex">
                <div class="text-right mt-4 mr-5">
                    <label class="text-muted font-weight-normal m-0">Скидка</label>
                    <div class="text-large"><strong>{{ 20 | currency }}</strong></div>
                </div>
                <div class="text-right mt-4">
                    <label class="text-muted font-weight-normal m-0">Итого</label>
                    <div class="text-large"><strong>{{ totalSum() | currency }}</strong></div>
                </div>
            </div>
        </div>

        <div class="float-right">
            <button type="button" class="btn btn-lg btn-default md-btn-flat mt-2 mr-3" routerLink="/product">Назад к покупкам</button>
            <button type="button" class="btn btn-lg btn-primary mt-2" routerLink="/checkout">Оформить</button>
        </div>
</div>
