<div id="shop-checkout-wizard-1" class="animated fadeIn" [formGroup]="shippingForm">
    <div class="form-group">
        <label class="form-label">Ваше имя</label>
        <input type="text" class="form-control" formControlName="customerName">
    </div>
    <div class="form-row">
        <div class="form-group col-md" *ngIf="false">
            <label class="form-label">Регион</label>
            <ng-select [items]="getRegions()"
                       bindLabel="label"
                       bindValue="value"
                       formControlName="regionId"
                       placeholder="Выберите регион">
                <ng-template ng-label-tmp let-item="item">
                    <span class="ng-value-label"><img [src]="item['flag']" width="20px" height="16px" class="align-middle mr-1"> {{item['label']}}</span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-value-label"><img [src]="item['flag']" width="20px" height="16px" class="align-middle mr-1"> {{item['label']}}</span>
                </ng-template>
            </ng-select>
        </div>
        <div class="form-group col-md" *ngIf="member && addresses">
            <label class="form-label">Сохраненные адреса</label>
            <ng-select [items]="addresses"
                       bindLabel="name"
                       placeholder="Выбрать из сохраненных адресов"
                       (change)="selectAddress($event)"
                       >
            </ng-select>
        </div>
        <div class="form-group col-md">
            <label class="form-label">Город</label>
            <input type="text" class="form-control" formControlName="city">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label class="form-label">Индекс</label>
            <input type="text" class="form-control" formControlName="postCode" mask="000000" [validation]="true">
        </div>
        <div class="form-group col-md-8">
            <label class="form-label">Адрес</label>
            <input type="text" class="form-control" formControlName="address">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md">
            <label class="form-label">Телефон</label>
            <input type="text" class="form-control" placeholder="+7(___) ___-____"
                   formControlName="phone"
                   prefix="+7" mask="(000) 000 00 00" [showMaskTyped]="true" [validation]="true">
        </div>
        <div class="form-group col-md">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" formControlName="email">
        </div>
    </div>

    <hr class="mx-n4 mx-lg-n5 my-4">

    <h6 class="pt-2 mb-4">Время доставки</h6>

    <div class="row">
        <div class="col-md-6 mb-4" *ngFor="let method of getDeliveryMethods()">
            <a [routerLink]="" class="d-block rounded ui-bordered p-3"
               (click)="selectDeliveryMethod(method.id)"
               [ngClass]="{
                    'text-primary': shippingForm.get('deliveryMethod').value == method.id,
                    'border-primary': shippingForm.get('deliveryMethod').value == method.id,
                    'text-body': shippingForm.get('deliveryMethod').value != method.id}"
            >
                <span>{{method.title}} - <strong>{{method.price == 0 ? 'бесплатно' : method.price | currency}}</strong>
              </span>
                <br>
                <small class="text-muted">{{method.description}}</small>
            </a>
        </div>
    </div>
</div>
