    <div class="card-body pb-2">

        <h6 class="mb-4">Аккаунт</h6>
        <div class="form-group">
            <label class="switcher">
                <input type="checkbox" class="switcher-input" [(ngModel)]="accountData.notifications.forum">
                <span class="switcher-indicator">
              <span class="switcher-yes"></span>
              <span class="switcher-no"></span>
            </span>
                <span class="switcher-label">Сообщать мне о статусе заказа</span>
            </label>
        </div>
        <div class="form-group">
            <label class="switcher">
                <input type="checkbox" class="switcher-input" [(ngModel)]="accountData.notifications.followings">
                <span class="switcher-indicator">
              <span class="switcher-yes"></span>
              <span class="switcher-no"></span>
            </span>
                <span class="switcher-label">Сообщать мне о подозрительных входах в мою учетную запись</span>
            </label>
        </div>
    </div>
    <hr class="border-light m-0">
    <div class="card-body pb-2">

        <h6 class="mb-4">Новости</h6>

        <div class="form-group">
            <label class="switcher">
                <input type="checkbox" class="switcher-input" [(ngModel)]="accountData.notifications.comments">
                <span class="switcher-indicator">
              <span class="switcher-yes"></span>
              <span class="switcher-no"></span>
            </span>
                <span class="switcher-label">Сообщать мне о акция</span>
            </label>
        </div>
        <div class="form-group">
            <label class="switcher">
                <input type="checkbox" class="switcher-input" [(ngModel)]="accountData.notifications.news">
                <span class="switcher-indicator">
              <span class="switcher-yes"></span>
              <span class="switcher-no"></span>
            </span>
                <span class="switcher-label">Новостная рассылка</span>
            </label>
        </div>
        <div class="form-group">
            <label class="switcher">
                <input type="checkbox" class="switcher-input" [(ngModel)]="accountData.notifications.products">
                <span class="switcher-indicator">
              <span class="switcher-yes"></span>
              <span class="switcher-no"></span>
            </span>
                <span class="switcher-label">Популярные товары недели</span>
            </label>
        </div>
    </div>
