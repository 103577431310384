import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-cart-view-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  constructor(
      private cartService: CartService
  ) { }

  ngOnInit() {
  }

  getCart() {
    return this.cartService.getCart();
  }

  totalSum() {
    return this.cartService.totalSum();
  }

  clearCart() {
    this.cartService.clearCart();
  }

  removeItem(product) {
    this.cartService.removeItem(product);
  }

}
