import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductsService } from '../products.service';
import { Product } from '../product';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { subscribeToPromise } from 'rxjs/internal-compatibility';
import { CartService } from '../../cart/cart.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: [
      './list.component.scss',
    '../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
  ],

})
export class ListComponent implements OnInit {
  category = 1;
  load = false;
  products: Product[] = [];

  changePrice = new EventEmitter();
  brands: Brand[] = [
    {name: 'Домик в деревне', count: 3},
    {name: 'Простоквашено', count: 15},
    {name: 'Мираторг', count: 7},
    {name: 'Мяснов', count: 9},
    {name: 'Вятская', count: 17},
    {name: 'Большевик', count: 4},
    {name: 'Дымов', count: 8},
  ];

  filter: ProductListFilter = {price: [1, 4000]};
  priceRangeConfig = {
    connect: true,
    step: 10,
    range: {
      min: 0,
      max: 4000
    },
    direction: 'ltr'
  };

  products$: Observable<Product[]>;
  private searchTerms = new Subject<string>();

  constructor(
      private productService: ProductsService
  ) { }

  ngOnInit() {
    this.loadData(1);
  }

  loadData(page) {
    this.productService.getList()
        .subscribe(data => {
          // this.totalItems = parseInt(data.headers.get('X-Pagination-Total-Count'), 10);
          // this.perPage = parseInt(data.headers.get('X-Pagination-Per-Page'), 10);
          // this.pageCount = parseInt(data.headers.get('X-Pagination-Page-Count'), 10);
          this.load = true;
          this.products = data;
        });
  }
}

export interface ProductListFilter {
  price: number[];
}

export interface Brand {
  name: string;
  count: number;
}
