    <hr class="d-none d-lg-block w-100 my-2">
    <ul class="navbar-nav">

        <!-- Women categories -->
        <li class="nav-item mega-dropdown" hoverDropdown ngbDropdown>
            <a class="nav-link dropdown-toggle small text-expanded pr-lg-3 pl-lg-0" href="#"  aria-expanded="false" ngbDropdownToggle>WOMEN</a>
            <div class="dropdown-menu p-0 mt-2 overflow-hidden" ngbDropdownMenu>
                <div class="row no-gutters row-bordered">
                    <div class="col-12 col-lg-5 p-4">
                        <h6 class="small font-weight-bold text-expanded">CLOTHING</h6>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="mega-link d-block text-body small mb-2">New In</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Dresses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Party Dresses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Bridesmaid Dresses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Little Black Dresses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Little Red Dresses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Maxi Dresses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tops</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Coats &amp; Jackets</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Knitwear</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Skirts</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Bodysuits</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Shirts &amp; Blouses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Jumpsuits &amp; Playsuits</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Trousers &amp; Leggings</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Co-ord Sets</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Lingerie</a>
                            </div>
                            <div class="col">
                                <a href="#" class="mega-link d-block text-body small mb-2">Onesies &amp; Nightwear</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Loungewear</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Swimwear &amp; Beachwear</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Jeans</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Sportswear</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tracksuits &amp; Sweats</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Suits &amp; Separates</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Shorts</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Denim</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Plus Size &amp; Curve</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Petite</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tall</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Maternity</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Style Steals</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="row no-gutters row-bordered">
                            <div class="col-6 col-lg-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">SHOES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">All Shoes</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Boots</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">High Heels</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Over the Knee Boots</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Flats</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Trainers</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Sandals</a>
                            </div>
                            <div class="col-6 col-lg-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">ACCESSORIES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">All Accessories</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Bags</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Jewellery</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Scarves, Hats &amp; Gloves</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Sunglasses</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Cosmetics</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Hair Accessories</a>
                            </div>
                        </div>
                    </div>

                    <!-- Presentation block -->
                    <div class="d-flex col-lg-4 align-items-stretch p-0">
                        <div class="d-flex flex-column justify-content-center align-items-center w-100 ui-bg-cover text-center text-white py-5" style="background-image: url('/products/appwork/v140/assets_/img/uikit/presentation-jeans.jpg');">
                            <div class="text-big font-weight-bold mt-5 mb-2">
                                LIMITED TIME ONLY!
                            </div>
                            <div class="display-3 font-weight-bolder mb-2">
                                25% OFF
                            </div>
                            <div class="mb-5">
                                USE CODE: <span class="font-weight-bold">SPRING</span>
                            </div>
                            <a href="#" class="btn btn-outline-white btn-lg d-block text-expanded">
                                SHOP NOW <i class="ion ion-ios-arrow-forward"></i>
                            </a>
                        </div>
                    </div>
                    <!-- / Presentation block -->

                </div>
            </div>
        </li>
        <!-- / Women categories -->

        <!-- Men categories -->
        <li class="nav-item mega-dropdown" id="shop-men-dropdown">
            <a class="nav-link dropdown-toggle small text-expanded px-lg-3" href="#" data-toggle="mega-dropdown" data-trigger="hover" aria-expanded="false">MEN</a>
            <div class="dropdown-menu p-0 mt-2">
                <div class="row no-gutters row-bordered">
                    <div class="col-6 col-lg-3 p-4">
                        <h6 class="small font-weight-bold text-expanded">CLOTHING</h6>
                        <a href="#" class="mega-link d-block text-body small mb-2">New In</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Coats &amp; Jackets</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">T-Shirts &amp; Vests</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Hoodies &amp; Sweatshirts</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Jeans</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Shirts</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Jumpers &amp; Cardigans</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Joggers &amp; Tracksuits</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Big &amp; Tall</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Trousers</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Suits</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Activewear</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Shorts &amp; Swim Shorts</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Polo Shirts</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Onesies &amp; Loungewear</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Style Steals</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Back In Stock</a>
                        <a href="#" class="mega-link d-block text-body small mb-2">Multi Packs</a>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="row no-gutters row-bordered">
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">SHOES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">View All</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Trainers</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Boots</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Smart Shoes</a>
                            </div>
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">ACCESSORIES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">View All</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Bags &amp; Wallets</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Belts</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Gifts &amp; Novelty</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Gloves, Hats &amp; Scarves</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Jewellery</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Hats</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Underwear &amp; Socks</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row no-gutters row-bordered">

                            <!-- Special deals -->
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">SPECIAL DEALS</h6>
                                <div class="w-100">
                                    <div class="swiper-container swiper-container-initialized swiper-container-horizontal" id="shop-men-special-deals" style="width: 100px;">
                                        <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
                                            <a href="#" class="swiper-slide d-block ui-bordered text-center text-body p-4 swiper-slide-active" style="width: 241px; margin-right: 24px;">
                                                <div class="shop-special-deal-label badge badge-success font-weight-bold">15% OFF</div>
                                                <img src="/products/appwork/v140/assets_/img/uikit/nike-1.jpg" class="mb-2" alt="" style="max-width: 75%; max-height: 200px">
                                                <div><span class="text-light" style="text-decoration: line-through;">$57.55</span>&nbsp; <strong>$48.92</strong></div>
                                            </a>
                                            <a href="#" class="swiper-slide d-block ui-bordered text-center text-body p-4 swiper-slide-next" style="width: 241px; margin-right: 24px;">
                                                <div class="shop-special-deal-label badge badge-success font-weight-bold">10% OFF</div>
                                                <img src="/products/appwork/v140/assets_/img/uikit/sunglasses.jpg" class="mb-2" alt="" style="max-width: 75%; max-height: 200px">
                                                <div><span class="text-light" style="text-decoration: line-through;">$20.55</span>&nbsp; <strong>$18.50</strong></div>
                                            </a>
                                            <a href="#" class="swiper-slide d-block ui-bordered text-center text-body p-4" style="width: 241px; margin-right: 24px;">
                                                <div class="shop-special-deal-label badge badge-success font-weight-bold">15% OFF</div>
                                                <img src="/products/appwork/v140/assets_/img/uikit/adidas.jpg" class="mb-2" alt="" style="max-width: 75%; max-height: 200px">
                                                <div><span class="text-light" style="text-decoration: line-through;">$57.55</span>&nbsp; <strong>$48.92</strong></div>
                                            </a>
                                            <a href="#" class="swiper-slide d-block ui-bordered text-center text-body p-4" style="width: 241px; margin-right: 24px;">
                                                <div class="shop-special-deal-label badge badge-success font-weight-bold">20% OFF</div>
                                                <img src="/products/appwork/v140/assets_/img/uikit/backpack.jpg" class="mb-2" alt="" style="max-width: 75%; max-height: 200px">
                                                <div><span class="text-light" style="text-decoration: line-through;">$160.00</span>&nbsp; <strong>$128.00</strong></div>
                                            </a>
                                        </div>
                                        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
                                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                </div>
                            </div>
                            <!-- / Special deals -->

                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">POPULAR BRANDS</h6>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-1.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-2.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-3.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-4.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-5.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-6.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-7.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-8.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-9.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                                <a href="#"><img src="/products/appwork/v140/assets_/img/uikit/logo-10.png" class="hoverable ui-w-80 ui-img-grayscale" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <!-- Men categories -->

        <!-- Electronics categories -->
        <li class="nav-item mega-dropdown">
            <a class="nav-link dropdown-toggle small text-expanded px-lg-3" href="#" data-toggle="mega-dropdown" data-trigger="hover" aria-expanded="false">ELECTORNICS</a>
            <div class="dropdown-menu p-0 mt-2">
                <div class="row no-gutters row-bordered">

                    <!-- Popular categories -->
                    <div class="col-sm-6 col-lg-3 p-4">
                        <h6 class="small font-weight-bold text-expanded">POPULAR CATEGORIES</h6>
                        <a href="#" class="media align-items-center ui-bordered text-body py-2 px-3 mb-2">
                            <img src="/products/appwork/v140/assets_/img/uikit/ps4.jpg" alt="" class="d-block ui-w-40">
                            <span class="media-body small font-weight-semibold ml-2">GAMING</span>
                        </a>
                        <a href="#" class="media align-items-center ui-bordered text-body py-2 px-3 mb-2">
                            <img src="/products/appwork/v140/assets_/img/uikit/s7edge-1.jpg" alt="" class="d-block ui-w-40">
                            <span class="media-body small font-weight-semibold ml-2">MOBILE PHONES</span>
                        </a>
                        <a href="#" class="media align-items-center ui-bordered text-body py-2 px-3 mb-2">
                            <img src="/products/appwork/v140/assets_/img/uikit/iwatch.jpg" alt="" class="d-block ui-w-40">
                            <span class="media-body small font-weight-semibold ml-2">SMART WATCHES</span>
                        </a>
                        <a href="#" class="media align-items-center ui-bordered text-body py-2 px-3 mb-2">
                            <img src="/products/appwork/v140/assets_/img/uikit/headphones.jpg" alt="" class="d-block ui-w-40">
                            <span class="media-body small font-weight-semibold ml-2">HEADPHONES</span>
                        </a>
                    </div>
                    <!-- / Popular categories -->

                    <div class="col-6 col-lg-3">
                        <div class="row no-gutters row-bordered">
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">MOBILE PHONES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">Octa Core</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Deca Core</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Single SIM Card</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Dual SIM Card</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">4GB RAM</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">5.5-inch Display</a>
                            </div>
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">MOBILE PHONES ACCESSORIES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">Mobile Phone Accessories</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Power Bank</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Screen Protectors</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Mobile Phone Cables</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Mobile Phone Chargers</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Holders &amp; Stands</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Mobile Phone Lenses</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="row no-gutters row-bordered">
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">LAPTOP &amp; TABLETS</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">Laptops</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Gaming Laptops</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tablets</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">2 in 1 Tablets</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Phone Call Tablets</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">iPad</a>
                            </div>
                            <div class="col-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">LAPTOP &amp; TABLETS ACCESSORIES</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">Laptop Bags &amp; Cases</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Laptop Batteries</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tablet Accessories</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tablet LCD Screens</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Tablet Cases</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="row no-gutters row-bordered">
                            <div class="col-6 col-sm-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">CAMERA &amp; PHOTO</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">Camera &amp; Photo</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Digital Cameras</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Camcorders</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Camera Drones</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Action Cameras</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Photo Studio</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Camera &amp; Photo Accessories</a>
                            </div>
                            <div class="col-6 col-sm-12 p-4">
                                <h6 class="small font-weight-bold text-expanded">NETWORKING</h6>
                                <a href="#" class="mega-link d-block text-body small mb-2">Wireless Routers</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Network Cards</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">3G Modems</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Modem-Router Combos</a>
                                <a href="#" class="mega-link d-block text-body small mb-2">Networking Tools</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <!-- / Electronics categories -->

        <li class="nav-item"><a class="nav-link small text-expanded px-lg-3" href="#">SALE</a></li>
        <li class="nav-item"><a class="nav-link small text-expanded px-lg-3" href="#">BLOG</a></li>
    </ul>
