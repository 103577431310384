<div class="bg-light">
    <div class="container py-4 px-3">
        <ol class="breadcrumb text-big m-0">
            <li class="breadcrumb-item">
                <a href="#">Категории</a>
            </li>
            <li class="breadcrumb-item" *ngFor="let category of breadsCrumbs" [class.active]="category.slug === parentSlug">
                <a routerLink="/category/{{category.getUrl()}}">{{category.name}}</a>
            </li>
        </ol>
    </div>
</div>

<div class="container px-3 my-4">
    child: {{child}}<br>
    parent: {{parentSlug}}<br>
    breadsCrumbs: {{breadsCrumbs.length}}<br>
    getData.length: {{getData().length}}<br>
    <div class="sk-wave sk-primary" *ngIf="!load">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
    </div>

    <div class="row" *ngIf="load">
        <div class="col order-2 order-lg-1">
            <!-- Nav -->
            <div class="d-flex flex-sm-row flex-wrap justify-content-between align-items-sm-center border-bottom-0 ui-bordered">
                <ul class="nav nav-tabs tabs-alt flex-shrink-1 order-3 order-sm-1 border-0 px-4">
                    <li class="nav-item">
                        <a class="nav-link small font-weight-normal text-expanded py-4 active" href="javascript:void(0)">Акции</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link small font-weight-normal text-expanded py-4" href="javascript:void(0)">Популярные</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link small font-weight-normal text-expanded py-4" href="javascript:void(0)">Новые</a>
                    </li>
                </ul>

                <hr class="d-sm-none order-2 w-100 m-0">

                <div class="order-1 order-sm-2 px-4">
                    <button type="button" class="btn icon-btn btn-outline-primary borderless my-2">
                        <span class="ion ion-md-apps"></span>
                    </button>
                    <button type="button" class="btn icon-btn btn-outline-default borderless my-2">
                        <span class="ion ion-md-list"></span>
                    </button>
                </div>
            </div>
            <!-- / Nav -->

            <!-- Products -->
            <div class="row row-bordered no-gutters ui-bordered">
                <app-category-list-item
                        class="col-sm-6 col-xl-4 text-center p-4"
                        *ngFor="let category of getData()"
                        [category]="category"
                ></app-category-list-item>
            </div>
            <!-- / Products -->

        </div>
    </div>
</div>
