import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService, httpOptions } from '../services/BaseService';
import { Category } from './category';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CategoryService {
    protected host = environment.host;
    private url = this.host + '/v1/category';

    constructor(private http: HttpClient) {}

    getList(page): Observable<HttpResponse<Category[]>> {
        // const url = `${this.url}?page=${page}`;
        return this.http.get<HttpResponse<Category[]>>(this.url, httpOptions);
    }
}
