import { environment } from '../../environments/environment';

export class Product {
    id: number;
    title: string;
    description: string;
    price: number;
    thumb: string;
    image: string;
    discount: boolean;
    orderedAmount = 0;

    constructor(json: any) {
        this.id = json.id;
        this.title = json.title;
        this.description = json.description;
        this.price = json.price;
        this.thumb = json.thumb;
        this.image = json.image;
        this.discount = json.discount;
        this.orderedAmount = (typeof json.orderedAmount !== 'undefined') ? json.orderedAmount : 0;
    }

    public getTotal() {
        return this.price * this.orderedAmount;
    }

    public getImageUrl() {
        return environment.storage_host + '/images/items/' + this.image;
    }
}
