import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListComponent} from './list/list.component';
import {ViewComponent} from './view/view.component';
import {LayoutWithoutSidenavComponent} from '../layout/layout-without-sidenav/layout-without-sidenav.component';
import { Layout1Component } from '../layout/layout-1/layout-1.component';
import { Layout1FlexComponent } from '../layout/layout-1-flex/layout-1-flex.component';
import { Layout2Component } from '../layout/layout-2/layout-2.component';
import { Layout2FlexComponent } from '../layout/layout-2-flex/layout-2-flex.component';
import { LayoutBlankComponent } from '../layout/layout-blank/layout-blank.component';
import { LayoutShopComponent } from '../layout/layout-shop/layout-shop.component';
// import {AuthGuard} from '../../services/auth.guard';


// *******************************************************************************
//

const routes: Routes = [
    { path: 'product', component: LayoutShopComponent, children: [
            { path: '', component: ListComponent},
            // { path: 'create', component: AdCreateComponent, canActivate: [AuthGuard]},
            { path: ':id', component: ViewComponent},
        ]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductRouting {
}
