<div class="card-body pb-2">
    <div class="form-group">
        <label class="form-label">Название адреса</label>
        <input [(ngModel)]="address.name" type="text" class="form-control">
    </div>
    <div class="form-group">
        <label class="form-label">Город</label>
        <input [(ngModel)]="address.city" type="text" class="form-control">
    </div>
    <div class="form-group">
        <label class="form-label">Индекс</label>
        <input [(ngModel)]="address.postIndex" type="text" class="form-control">
    </div>
    <div class="form-group">
        <label class="form-label">Адресс</label>
        <input [(ngModel)]="address.address" type="text" class="form-control">
    </div>
    <button class="btn btn-primary" (click)="addAddress()">Добавить адрес</button>

</div>
<hr class="border-light m-0">
<div class="card-body pb-2">

    <h6 class="mb-4">Адреса</h6>
    <div class="form-group" *ngFor="let address of addresses">
        <label class="form-label">{{address.name}}</label>
        {{address.postIndex}}, {{address.city}}, {{address.address}} <a href="javascript:void(0)"><i class="ion ion-ios-trash"></i></a>
    </div>
</div>
