import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
    styleUrls: [
        './sign-up.component.scss',
        '../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
    ]

})
export class SignUpComponent implements OnInit {
    signUpForm = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        phone: ['', [Validators.required]],
        agreement: ['', [Validators.requiredTrue]],
    });

    blockButton = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router
    ) {
        if (this.authService.isLoggedIn()) {
            this.router.navigate([this.authService.redirectUrl]);
        }
    }

    ngOnInit(): void {
    }

    submitForm() {
        this.blockButton = true;
        this.authService.signUp(this.signUpForm.getRawValue()).subscribe(
            data => {
                this.authService.setAuthorizationToken(data.body);
                this.authService.updateCurrentMemberData();
            },
            error => {
                this.blockButton = false;
            },
            () => {
                this.router.navigate([this.authService.redirectUrl]);
            }
        );
    }
}
