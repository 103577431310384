import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view/view.component';
import { CheckoutRouting } from './checkout-routing.module';
import { ShippingComponent } from './view/shipping/shipping.component';
import { PaymentComponent } from './view/payment/payment.component';
import { ConfirmationComponent } from './view/confirmation/confirmation.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    declarations: [
        ViewComponent,
        ShippingComponent,
        PaymentComponent,
        ConfirmationComponent,
    ],
    imports: [
        ArchwizardModule,
        CommonModule,
        CheckoutRouting,
        NgbModule,
        FormsModule,
        NgSelectModule,
        NgxMaskModule.forRoot(options),
        ReactiveFormsModule,
    ]
})
export class CheckoutModule {
}
