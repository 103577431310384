<div class="card-body media align-items-center">
    <img [src]="member.getAvatar()" alt="" class="rounded-circle d-block ui-w-80">
    <div class="media-body ml-4">
        <button type="button" class="btn btn-outline-primary" (click)="open(uploadAvatar)">Изменить фото</button> &nbsp;
        <button type="button" class="btn btn-default md-btn-flat">Удалить фото</button>
        <div class="text-light small mt-1">Разрешены типы JPG, GIF or PNG. Максимальный размер 1Mb</div>
    </div>
</div>
<hr class="border-light m-0">

<div class="card-body">
    <div class="form-group">
        <label class="form-label">Имя</label>
        <input [(ngModel)]="member.name" type="text" class="form-control">
    </div>
    <div class="form-group">
        <label class="form-label">День рождения</label>
        <input class="form-control" placeholder="dd-mm-yyyy" name="dp"
               ngbDatepicker
               [(ngModel)]="member.birthday"
               [displayMonths]="1"
               [navigation]="'select'"
               [maxDate]="maxDate"
               [placement]="'bottom-left'"
               (click)="d.toggle()"
               #d="ngbDatepicker">
    </div>
    <div class="form-group">
        <label class="form-label">E-mail</label>
        <input [(ngModel)]="member.email" type="text" class="form-control mb-1">
        <div class="alert alert-warning mt-3" *ngIf="member.email && !member.emailVerified">
            Ваш email не подтвержден. Пожалуйста, проверьте вашу почту.<br>
            <a href="javascript:void(0)">Отправить еще раз</a>
        </div>
    </div>
</div>
<hr class="border-light m-0">
<div class="card-body pb-2">

    <h6 class="mb-4">Контакты</h6>
    <div class="form-group">
        <label class="form-label">Телефон</label>
        <input [(ngModel)]="member.phone" type="text" class="form-control" prefix="+7" mask="(000) 000 00 00" [showMaskTyped]="true" [validation]="true">
    </div>
    <div class="form-group">
        <label class="form-label">Телеграм</label>
        <input [(ngModel)]="member.telegram" type="text" class="form-control">
    </div>

</div>

<!-- Modal template -->
<ng-template #uploadAvatar let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">
            Фото<br>
            <small class="text-muted">Разрешены типы JPG, PNG. Максимальный размер 1Mb</small>
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
    </div>
    <div class="modal-body">
        <label class="btn btn-primary btn">
            Загрузить фото
            <input type="file" (change)="fileChangeListener($event)" class="sr-only">
        </label>
            <div style="width: 100%;">
                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1/1"
                    [roundCropper]="true"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                ></image-cropper>
            </div>
        <ngb-progressbar [value]="uploadProgress" [type]="uploadProgressStatus"></ngb-progressbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="c('Close click')">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="uploadAttachmentToServer(uploadAvatar)">Сохранить</button>
    </div>
</ng-template>
