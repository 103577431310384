import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view/view.component';
import { HistoryComponent } from './history/history.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { ProfileRouting } from './profile-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { GeneralComponent } from './view/general/general.component';
import { SecurityComponent } from './view/security/security.component';
import { AddressComponent } from './view/address/address.component';
import { PaymentComponent } from './view/payment/payment.component';
import { NotificationComponent } from './view/notification/notification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
    declarations: [
        ViewComponent,
        HistoryComponent,
        FavoriteComponent,
        GeneralComponent,
        SecurityComponent,
        AddressComponent,
        PaymentComponent,
        NotificationComponent
    ],
    imports: [
        CommonModule,
        ProfileRouting,
        FormsModule,
        NgSelectModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NgbModule,
        ImageCropperModule,
    ]
})
export class ProfileModule {
}
