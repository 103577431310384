import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-view-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() accountData;

  constructor() { }

  ngOnInit(): void {
  }

}
