import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ViewComponent} from './view/view.component';
import {LayoutWithoutSidenavComponent} from '../layout/layout-without-sidenav/layout-without-sidenav.component';
import { HistoryComponent } from './history/history.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { LayoutShopComponent } from '../layout/layout-shop/layout-shop.component';
// import {AuthGuard} from '../../services/auth.guard';


// *******************************************************************************
//

const routes: Routes = [
    { path: 'profile', component: LayoutShopComponent, children: [
            { path: '', component: ViewComponent},
            { path: 'orders', component: HistoryComponent},
            { path: 'favorites', component: FavoriteComponent},
        ]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRouting {
}
