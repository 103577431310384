<div class="bg-light">
    <div class="container py-4 px-3">
        <ol class="breadcrumb text-big m-0">
            <li class="breadcrumb-item">
                <a href="#">Категории</a>
            </li>
            <li class="breadcrumb-item active">Популярное</li>
        </ol>
    </div>
</div>

<div class="container px-3 my-4">
    <div class="sk-wave sk-primary" *ngIf="!load">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
    </div>

    <div class="row" *ngIf="load">
        <div class="col order-2 order-lg-1">
            <!-- Nav -->
            <div class="d-flex flex-sm-row flex-wrap justify-content-between align-items-sm-center border-bottom-0 ui-bordered">
                <ul class="nav nav-tabs tabs-alt flex-shrink-1 order-3 order-sm-1 border-0 px-4">
                    <li class="nav-item">
                        <a class="nav-link small font-weight-normal text-expanded py-4 active" href="javascript:void(0)">Акции</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link small font-weight-normal text-expanded py-4" href="javascript:void(0)">Популярные</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link small font-weight-normal text-expanded py-4" href="javascript:void(0)">Новые</a>
                    </li>
                </ul>

                <hr class="d-sm-none order-2 w-100 m-0">

                <div class="order-1 order-sm-2 px-4">
                    <button type="button" class="btn icon-btn btn-outline-primary borderless my-2">
                        <span class="ion ion-md-apps"></span>
                    </button>
                    <button type="button" class="btn icon-btn btn-outline-default borderless my-2">
                        <span class="ion ion-md-list"></span>
                    </button>
                </div>
            </div>
            <!-- / Nav -->

            <!-- Products -->
            <div class="row row-bordered no-gutters ui-bordered">
                <app-product-list-item class="col-sm-6 col-xl-4 text-center p-4"
                        *ngFor="let product of products"
                        [product]="product"
                ></app-product-list-item>
            </div>
            <!-- / Products -->

            <!-- Pagination -->
            <nav class="mt-4">
                <ul class="pagination flex-wrap justify-content-center">
                    <li class="page-item disabled">
                        <a class="page-link" href="#">«</a>
                    </li>
                    <li class="page-item active">
                        <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">4</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">5</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">»</a>
                    </li>
                </ul>
            </nav>
            <!-- / Pagination -->

        </div>

        <!-- Filters -->
        <div id="shop-filters-block" class="d-none d-lg-block col-lg-4 col-xl-3 order-1 order-lg-2 mb-4">
            <div class="row no-gutters row-bordered ui-bordered">

                <div class="col-12">
                    <a class="d-flex justify-content-between text-body py-3 px-4" data-toggle="collapse" href="#shop-filters-1" aria-expanded="true">
                        Стоимость
                        <div class="collapse-icon"></div>
                    </a>
                    <div class="collapse show" id="shop-filters-1">
                        <div class="px-4 pb-3">

                            <nouislider #priceSlider class="noUi-primary" [behaviour]="'tap-drag'" [tooltips]="true" [config]="priceRangeConfig" [(ngModel)]="filter.price"></nouislider>

                            <div id="shop-price-slider" class="shop-filters-slider noUi-primary mt-2 mb-4"></div>

                            <div class="input-group input-group-sm mt-3">
                                <input id="shop-price-slider-start" type="text" class="form-control rounded-0 text-center"
                                    (change)="priceSlider.writeValue(filter.price)" [(ngModel)]="filter.price[0]"
                                >
                                <div class="input-group-prepend">
                                    <div class="input-group-text bg-transparent border-0">–</div>
                                </div>
                                <input id="shop-price-slider-end" type="text" class="form-control rounded-0 text-center" [(ngModel)]="filter.price[1]" (change)="priceSlider.writeValue(filter.price)">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <a class="d-flex justify-content-between text-body py-3 px-4" data-toggle="collapse" href="#shop-filters-2" aria-expanded="true">
                        Бренд
                        <div class="collapse-icon"></div>
                    </a>
                    <div class="collapse show" id="shop-filters-2">
                        <div class="px-4 pb-3">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control form-control-sm" placeholder="Search">
                                <span class="input-group-append">
                        <button type="button" class="btn btn-sm btn-secondary icon-btn md-btn-flat">
                          <span class="ion ion-ios-search"></span>
                        </button>
                      </span>
                            </div>

                            <div class="custom-controls-stacked">
                                <label class="custom-control custom-checkbox justify-content-between mr-0" *ngFor="let brand of brands">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-label">{{brand.name}}</span>
                                    <span class="float-right text-muted">{{brand.count}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 py-3 px-4">
                    <label class="custom-control custom-checkbox m-0">
                        <input type="checkbox" class="custom-control-input">
                        <span class="custom-control-label">Акция</span>
                    </label>
                </div>

                <div class="col-12 py-3 px-4">
                    <button type="button" class="btn btn-primary btn-block text-expanded"><span class="small">Показать</span></button>
                </div>

            </div>
        </div>
        <!-- / Filters -->

    </div>
</div>
