import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthRouting } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
  declarations: [SignInComponent, SignUpComponent],
    imports: [
        CommonModule,
        AuthRouting,
        FormsModule,
        NgxMaskModule,
        ReactiveFormsModule,
        LaddaModule,
    ]
})
export class AuthModule { }
