import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { LayoutShopComponent } from '../layout/layout-shop/layout-shop.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
// import {AuthGuard} from '../../services/auth.guard';


// *******************************************************************************
//

const routes: Routes = [
    { path: 'auth', component: LayoutShopComponent, children: [
            { path: '', component: SignInComponent},
            { path: 'sign-in', component: SignInComponent},
            { path: 'sign-up', component: SignUpComponent},
        ]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRouting {
}
