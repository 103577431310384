import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        protected toastr: ToastrService,
        protected router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    protected handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            errorMessage = `Error: ${error.error.message}`;
        } else {
            if (error.status === 401 || error.status === 403) {
                this.router.navigate(['/auth/sign-in']);
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);

            console.error(error.error.message);
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

            this.toastr.error(error.error.message, error.error.name);
        }

        // return an observable with a user-facing error message
        return throwError(errorMessage);
    }
}
