import { Injectable } from '@angular/core';
import { Region } from './region';
import { DeliveryMethod } from './DeliveryMethod';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  regions = [
    {value: 1, label: 'Республика Адыгея', flag: 'assets/img/flag.svg'},
    {value: 2, label: 'Республика Алтай ', flag: 'assets/img/flag.svg'},
    {value: 3, label: 'Республика Башкортостан ', flag: 'assets/img/flag.svg'},
    {value: 4, label: 'Республика Бурятия ', flag: 'assets/img/flag.svg'},
    {value: 5, label: 'Республика Дагестан ', flag: 'assets/img/flag.svg'},
    {value: 6, label: 'Республика Ингушетия ', flag: 'assets/img/flag.svg'},
    {value: 7, label: 'Кабардино-Балкарская Республика', flag: 'assets/img/flag.svg'},
    {value: 8, label: 'Республика Калмыкия ', flag: 'assets/img/flag.svg'},
    {value: 9, label: 'Карачаево-Черкесская Республика', flag: 'assets/img/flag.svg'},
    {value: 10, label: 'Республика Карелия ', flag: 'assets/img/flag.svg'},
    {value: 11, label: 'Республика Коми ', flag: 'assets/img/flag.svg'},
    {value: 12, label: 'Республика Марий Эл ', flag: 'assets/img/flag.svg'},
    {value: 13, label: 'Республика Мордовия ', flag: 'assets/img/flag.svg'},
    {value: 14, label: 'Республика Саха (Якутия) ', flag: 'assets/img/flag.svg'},
    {value: 15, label: 'Республика Северная Осетия - Алания ', flag: 'assets/img/flag.svg'},
    {value: 16, label: 'Республика Татарстан', flag: 'assets/img/flag.svg'},
    {value: 17, label: 'Республика Тыва ', flag: 'assets/img/flag.svg'},
    {value: 18, label: 'Удмуртская Республика ', flag: 'assets/img/flag.svg'},
    {value: 19, label: 'Республика Хакасия ', flag: 'assets/img/flag.svg'},
    {value: 20, label: 'Чеченская Республика', flag: 'assets/img/flag.svg'},
    {value: 21, label: 'Чувашская Республика', flag: 'assets/img/flag.svg'},
    {value: 22, label: 'Алтайский край', flag: 'assets/img/flag.svg'},
    {value: 75, label: 'Забайкальский край', flag: 'assets/img/flag.svg'},
    {value: 41, label: 'Камчатский край', flag: 'assets/img/flag.svg'},
    {value: 23, label: 'Краснодарский край', flag: 'assets/img/flag.svg'},
    {value: 24, label: 'Красноярский край', flag: 'assets/img/flag.svg'},
    {value: 59, label: 'Пермский край', flag: 'assets/img/flag.svg'},
    {value: 25, label: 'Приморский край', flag: 'assets/img/flag.svg'},
    {value: 26, label: 'Ставропольский край', flag: 'assets/img/flag.svg'},
    {value: 27, label: 'Хабаровский край', flag: 'assets/img/flag.svg'},
    {value: 28, label: 'Амурская область ', flag: 'assets/img/flag.svg'},
    {value: 29, label: 'Архангельская область', flag: 'assets/img/flag.svg'},
    {value: 30, label: 'Астраханская область ', flag: 'assets/img/flag.svg'},
    {value: 31, label: 'Белгородская область', flag: 'assets/img/flag.svg'},
    {value: 32, label: 'Брянская область ', flag: 'assets/img/flag.svg'},
    {value: 33, label: 'Владимирская область ', flag: 'assets/img/flag.svg'},
    {value: 34, label: 'Волгоградская область ', flag: 'assets/img/flag.svg'},
    {value: 35, label: 'Вологодская область ', flag: 'assets/img/flag.svg'},
    {value: 36, label: 'Воронежская область ', flag: 'assets/img/flag.svg'},
    {value: 37, label: 'Ивановская область ', flag: 'assets/img/flag.svg'},
    {value: 38, label: 'Иркутская область ', flag: 'assets/img/flag.svg'},
    {value: 39, label: 'Калининградская область', flag: 'assets/img/flag.svg'},
    {value: 40, label: 'Калужская область ', flag: 'assets/img/flag.svg'},
    {value: 42, label: 'Кемеровская область ', flag: 'assets/img/flag.svg'},
    {value: 43, label: 'Кировская область ', flag: 'assets/img/flag.svg'},
    {value: 44, label: 'Костромская область ', flag: 'assets/img/flag.svg'},
    {value: 45, label: 'Курганская область ', flag: 'assets/img/flag.svg'},
    {value: 46, label: 'Курская область ', flag: 'assets/img/flag.svg'},
    {value: 47, label: 'Ленинградская область ', flag: 'assets/img/flag.svg'},
    {value: 48, label: 'Липецкая область ', flag: 'assets/img/flag.svg'},
    {value: 49, label: 'Магаданская область', flag: 'assets/img/flag.svg'},
    {value: 50, label: 'Московская область ', flag: 'assets/img/flag.svg'},
    {value: 51, label: 'Мурманская область ', flag: 'assets/img/flag.svg'},
    {value: 52, label: 'Нижегородская область ', flag: 'assets/img/flag.svg'},
    {value: 53, label: 'Новгородская область ', flag: 'assets/img/flag.svg'},
    {value: 54, label: 'Новосибирская область ', flag: 'assets/img/flag.svg'},
    {value: 55, label: 'Омская область', flag: 'assets/img/flag.svg'},
    {value: 56, label: 'Оренбургская область ', flag: 'assets/img/flag.svg'},
    {value: 57, label: 'Орловская область ', flag: 'assets/img/flag.svg'},
    {value: 58, label: 'Пензенская область ', flag: 'assets/img/flag.svg'},
    {value: 60, label: 'Псковская область ', flag: 'assets/img/flag.svg'},
    {value: 61, label: 'Ростовская область ', flag: 'assets/img/flag.svg'},
    {value: 62, label: 'Рязанская область ', flag: 'assets/img/flag.svg'},
    {value: 63, label: 'Самарская область ', flag: 'assets/img/flag.svg'},
    {value: 64, label: 'Саратовская область ', flag: 'assets/img/flag.svg'},
    {value: 65, label: 'Сахалинская область ', flag: 'assets/img/flag.svg'},
    {value: 66, label: 'Свердловская область ', flag: 'assets/img/flag.svg'},
    {value: 67, label: 'Смоленская область ', flag: 'assets/img/flag.svg'},
    {value: 68, label: 'Тамбовская область ', flag: 'assets/img/flag.svg'},
    {value: 69, label: 'Тверская область ', flag: 'assets/img/flag.svg'},
    {value: 70, label: 'Томская область ', flag: 'assets/img/flag.svg'},
    {value: 71, label: 'Тульская область', flag: 'assets/img/flag.svg'},
    {value: 72, label: 'Тюменская область ', flag: 'assets/img/flag.svg'},
    {value: 73, label: 'Ульяновская область ', flag: 'assets/img/flag.svg'},
    {value: 74, label: 'Челябинская область ', flag: 'assets/img/flag.svg'},
    {value: 76, label: 'Ярославская область', flag: 'assets/img/flag.svg'},
    {value: 77, label: 'Москва', flag: 'assets/img/flag.svg'},
    {value: 78, label: 'Санкт-Петербург', flag: 'assets/img/flag.svg'},
    {value: 79, label: 'Еврейская АО', flag: 'assets/img/flag.svg'},
    {value: 80, label: 'Ненецкий АО', flag: 'assets/img/flag.svg'},
    {value: 81, label: 'Ханты-Мансийский АО', flag: 'assets/img/flag.svg'},
    {value: 82, label: 'Чукотский АО', flag: 'assets/img/flag.svg'},
    {value: 83, label: 'Ямало-Ненецкий АО', flag: 'assets/img/flag.svg'}
  ];
  deliveryMethods: DeliveryMethod[] = [
    {id: 1, title: 'На следующий день', price: 0, description: 'Доставка осуществляется в течении 24 часов'},
    {id: 2, title: 'Сегодня', price: 300, description: 'В течении 4 часов'},
    {id: 3, title: 'Экспресс', price: 500,
      description: 'В течении 2 часов. Зависит от загруженности мгазинов, дорог, удаленности магазина'},
  ];

  constructor() { }

  getRegions(): Region[] {
    return this.regions;
  }

  findRegionById(regionId: number) {
    return this.regions.find((region: Region) => {
      if (region.value === regionId) {
        return true;
      }
      return false;
    });
  }

  getDeliveryMethods(): DeliveryMethod[] {
    return this.deliveryMethods;
  }

  findDeliveryById(methodId: number) {
    return this.deliveryMethods.find((method: DeliveryMethod) => {
      if (method.id === methodId) {
        return true;
      }
      return false;
    });
  }

}
