import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from './list/list.component';
import { LayoutShopComponent } from '../layout/layout-shop/layout-shop.component';

// *******************************************************************************

const routes: Routes = [
    {
        path: 'category', component: LayoutShopComponent, children: [
            {path: '**', component: ListComponent},
            // { path: 'create', component: AdCreateComponent, canActivate: [AuthGuard]},
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CategoryRouting {
}
