import { environment } from '../../environments/environment';

export class Member {
    name: string = 'Гость';
    birthday: string = '2000-01-01';
    phone: string = '';
    phoneVerified: boolean = false;
    email: string = '';
    emailVerified: boolean = false;
    avatar: string = 'guest.png';
    telegram: string = '';

    constructor(json: any = null) {
        if (json != null) {
            this.name = json.name;
            this.birthday = json.birthday;
            this.phone = json.phone;
            this.phoneVerified = json.phone_verified;
            this.email = json.email;
            this.emailVerified = json.email_verified;
            this.avatar = json.avatar;
        }
    }

    getAvatar(): string {
        return environment.storage_host + '/profile/' + this.avatar;
    }
}
