import { Component, Input, OnInit } from '@angular/core';
import { Category } from '../../category';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() category: Category;

  constructor() { }

  ngOnInit(): void {
  }

}
