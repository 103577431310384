<div class="bg-light">
    <div class="container py-4 px-3">
        <ol class="breadcrumb text-big m-0">
            <li class="breadcrumb-item">
                <a href="#">Регистрация</a>
            </li>
        </ol>
    </div>
</div>

<div class="container px-3 my-4">
    <form [formGroup]="signUpForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
            <label class="col-form-label col-sm-2 text-sm-right">Телефон</label>
            <div class="col-sm-10">
                <input formControlName="phone" class="form-control" type="text" prefix="+7" mask="(000) 000 00 00" [showMaskTyped]="true" [validation]="true">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-form-label col-sm-2 text-sm-right">Имя</label>
            <div class="col-sm-10">
                <input formControlName="name" class="form-control" type="text" placeholder="Имя">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-form-label col-sm-2 text-sm-right">Пароль</label>
            <div class="col-sm-10">
                <input formControlName="password" class="form-control" type="password" placeholder="Пароль">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-form-label col-sm-2 text-sm-right">Соглашение</label>
            <div class="col-sm-10">
                <label class="custom-control custom-checkbox mt-1">
                    <input formControlName="agreement" type="checkbox" class="custom-control-input">
                    <span class="custom-control-label text-muted small">
                        Я прочел <a href="javascript:void(0)">соглашение</a> и соглашаюсь с его условиями
                    </span>
                </label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-10 ml-sm-auto">
                <button type="submit" class="btn btn-primary" [disabled]="!signUpForm.valid" [ladda]="blockButton" data-style="slide-left">Регистрация</button>
            </div>
        </div>
    </form>
</div>
