<h4 class="font-weight-bold py-3 mb-4">Home</h4>
<p>This page is an example of basic layout. For more options use <strong>Angular starter template generator</strong> in
    the docs.</p>

{{ 123 | currency }}
{{ '12.12.2019' | date }}
<div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card text-center mb-3">
            <div class="card-body">
                <div class="clearfix">
                    <span class="ui-product-badge badge badge-default float-left">SALE</span>

                    <a href="#" class="float-right text-lighter">
                        <i class="ion ion-ios-heart"></i>
                    </a>
                </div>

                <a href="#" class="d-block">
                    <img src="https://gagadget.com/media/cache/a4/8e/a48e2f8236ae62d57882d335f1cb5ec2.jpg" class="mb-2"
                         alt
                         style="max-width: 75%; max-height: 200px">
                </a>

                <h6><a href="#" class="text-body">Wireless headphones</a></h6>
                <strong class="d-block mb-3">$235.55</strong>
                <a href="#" class="btn btn-outline-primary">Buy Now</a>
            </div>
        </div>
    </div>
</div>
