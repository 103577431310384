import { Component, Input, HostBinding, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../app.service';
import { LayoutService } from '../../layout.service';
import { CartService } from '../../../cart/cart.service';
import { AuthService } from '../../../auth/auth.service';
import { Member } from '../../../profile/Member';

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styleUrls: ['../../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [':host { display: block; }'],
})
export class LayoutNavbarComponent {
  isExpanded = false;
  searchExpanded = false;
  member: Member;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') hostClassMain = true;

  constructor(
      private authService: AuthService,
      private appService: AppService,
      private layoutService: LayoutService,
      private cartService: CartService
  ) {
    authService.currentMember$.subscribe(
        member => {
          this.member = member;
        }
    );
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  cartSize() {
    return this.cartService.getCartSize();
  }

  isLogged() {
    return this.authService.isLoggedIn();
  }

  logout() {
    this.authService.logout();
  }
}
