<nav class="footer bp-3 bg-lighter pt-4">
  <div class="container px-3 pt-4">
    <div class="row">
      <div class="col-lg-3 pr-lg-4 pb-4">
        <a href="#" class="footer-text d-block text-large font-weight-bolder mb-3">Product24</a>
        <p>
          Моментальная онлайн доставка продуктов из сетевых розничных магазинов.
        </p>
        <a class="text-twitter" href="#">
          <i class="ion ion-logo-twitter"></i>
        </a>
        &nbsp; &nbsp;
        <a class="text-facebook" href="#">
          <i class="ion ion-logo-facebook"></i>
        </a>
      </div>

      <div class="col">
        <div class="row">
          <div class="col-4 col-sm-3 col-md pb-4">
            <div class="footer-text small font-weight-bold mb-3">О нас</div>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Кто мы</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Наш магазин</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Доставка</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Работа у нас</a>
          </div>
          <div class="col-4 col-sm-3 col-md pb-4">
            <div class="footer-text small font-weight-bold mb-3">Категории</div>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Акции</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Новинки</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Постное</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Вегатерианцам</a>
          </div>
          <div class="col-4 col-sm-3 col-md pb-4">
            <div class="footer-text small font-weight-bold mb-3">Поддержка</div>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">База знаний</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Тех.поддержка</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Контакты</a>
          </div>
          <div class="col-4 col-sm-3 col-md pb-4">
            <div class="footer-text small font-weight-bold mb-3">Партнеры</div>
            <a href="#" class="footer-link d-block pb-2">Перекресток</a>
            <a href="#" class="footer-link d-block pb-2">Магнит</a>
            <a href="#" class="footer-link d-block pb-2">Метро</a>
          </div>
          <div class="col-4 col-sm-3 col-md pb-4">
            <div class="footer-text small font-weight-bold mb-3">Профиль</div>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Вход</a>
            <a href="javascript:void(0)" class="footer-link d-block pb-2">Регистрация</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr class="m-0">
  <div class="container py-4 px-3">
    <div>© 2020. All rights reserved</div>
  </div>
</nav>
